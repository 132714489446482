<template>
    <transition
    enter-active-class="duration-300"
    enter-from-class="scale-75 opacity-25"
    enter-to-class="scale-100 opacity-100"
    leave-active-class="duration-200"
    leave-from-class="opacity-100 scale-100"
    leave-to-class="scale-90 opacity-50">
        <slot />
    </transition>
</template>